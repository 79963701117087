import React, { useState } from "react";
import Rating from "react-rating";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import PrimaryInput from "../inputs/PrimaryInput";
import { createReview } from "../../../services/ReviewsServices";
import SpinnerLoader from "../loader/SpinnerLoader";
const MySwal = withReactContent(Swal);
export default function CreateReviewPopup({ handleToggle }) {
	const [fields, setFields] = useState({
		reviewRating: 0,
		reviewTitle: "",
		images: [],
		last_name: "",
		first_name: "",
		productID: "",
		country: "",
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "photo") {
			if (e.target.files[0]) {
				if (e.target.files[0].size > 8388608) {
					MySwal.fire({
						icon: "error",
						title: `Image cannot be greater than 8MB`,
						timer: 4000,
					});
					return true;
				}
				if (fields.images.length === 4) {
					return;
				}
				const file = e.target.files[0];
				const imageObj = {
					preview: URL.createObjectURL(file),
					file: file,
				};
				let img_arr = [...fields.images];
				img_arr.push(imageObj);
				setFields({
					...fields,
					[e.target.name]: file,
					images: img_arr,
				});
			}
			return;
		}
		setFields((pre) => ({ ...pre, [name]: value }));
	};
	const handleStarsChange = (value) => {
		setFields({
			...fields,
			reviewRating: value,
		});
	};

	const handleImageRemove = (index) => {
		let state_obj = { ...fields };
		try {
			state_obj.images = state_obj?.images?.filter((_, i) => i !== index);
		} catch (error) {
			console.log(error, "error");
		}
		setFields(state_obj);
	};
	const handleSubmit = async (e) => {
		const imagesArr =
			fields?.images?.length > 0
				? fields.images.map((item) => item.file)
				: undefined;
		let review = {
			product_id: fields?.productID || undefined,
			rating: fields.reviewRating || undefined,
			review: fields?.reviewTitle || undefined,
			images: imagesArr || undefined,
			first_name: fields?.first_name || undefined,
			last_name: fields?.last_name || undefined,
			country: fields?.country || undefined,
			created_at:fields?.created_at || undefined,
		};
		setLoading(true);
		try {
			const response = await createReview(review);
			if (response.status === 200) {
				MySwal.fire({
					icon: "success",
					title: "Review created successfully",
					timer: 4000,
				});
				handlePopupClose();
				window.location.reload();
			}
		} catch (error) {
			console.log(error, "check status");
			if (!error.response.status) {
				MySwal.fire({
					icon: "error",
					title: "Please try again",
					timer: 4000,
				});
				return;
			} else {
				MySwal.fire({
					icon: "error",
					title: error.response.data.message || "Please try again",
					timer: 4000,
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const handlePopupClose = () => {
		setFields({
			reviewRating: 0,
			reviewTitle: "",
			images: [],
			last_name: "",
			first_name: "",
			productID: "",
		});
		handleToggle();
	};

	console.log(fields, "check fields");
	return (
		<div className="fixed w-full h-full top-0 left-0 overflow-y-auto flex items-start justify-center py-6 px-4 sm:p-6 margin-auto bg-black  bg-opacity-50  z-[100000]">
			{loading && (
				<div className="bg-black  bg-opacity-50 h-[150px] absolute z-[999] top-[100px] w-[250px] rounded-lg">
					<SpinnerLoader />
				</div>
			)}
			<div className="bg-white p-5  rounded-[7px]  border border-gray-50 shadow-lg relative max-w-xl w-full sm:w-[490px] product-review-popup-wrapper mt-9">
				<div className="mb-3">
					<PrimaryInput
						label={"Product ID"}
						placeholder="Enter Product ID"
						width="w-full"
						value={fields?.productID || ""}
						handleChange={handleChange}
						name={"productID"}
						labelClassName="font-semibold"
					/>
				</div>
				<div className="flex items-center justify-between mb-3">
					<PrimaryInput
						label={"First Name"}
						placeholder="Enter First Name"
						width="w-full"
						value={fields?.first_name || ""}
						handleChange={handleChange}
						name={"first_name"}
						labelClassName="font-semibold"
					/>
					<div className="ml-4">
						<PrimaryInput
							label={"Last Name"}
							placeholder="Enter Last Name"
							width="w-full"
							value={fields?.last_name || ""}
							handleChange={handleChange}
							name={"last_name"}
							labelClassName="font-semibold"
						/>
					</div>
				</div>
				<div className="mb-3">
					<label className="text-sm mb-1 font-semibold">
						Country
					</label>
					<select
						className="rounded border border-[#BDBDBD] bg-transparent placeholder-[#828282] text-[13px] py-2.5 px-3.5   outline-none  w-full"
						name="country"
						value={fields?.country || ""}
						onChange={handleChange}
					>
						<option value={""}>Please select country</option>
						<option value={"US"}>United States</option>
						<option value={"CA"}>Canada</option>
						<option value={"AU"}>Australia</option>
						<option value={"GB"}>United Kingdom</option>
					</select>
				</div>

				<div className="text-black font-semibold mb-1">
					Overall Rating
				</div>
				<div className="flex items-center">
					<Rating
						initialRating={fields?.reviewRating}
						onChange={handleStarsChange}
						readonly={false}
						emptySymbol={
							<div className="w-7 h-7">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="text-blue-light"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={1}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
									/>
								</svg>
							</div>
						}
						fullSymbol={
							<div className="w-7 h-7">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="text-blue-light"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
							</div>
						}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="review-title" className="font-semibold">
						How was your experience
					</label>
					<textarea
						draggable={false}
						className=" text-black resize-none w-full border border-gray-350 h-28 focus:outline-none px-3 py-4 rounded-md"
						name="reviewTitle"
						onChange={handleChange}
						value={fields?.reviewTitle}
						placeholder="Write your comments here"
						id="review-title"
					></textarea>
				</div>
				<div className="flex justify-between">
					<div>
						<label className="font-semibold">Date </label>
						<input
							type="date"
							name="created_at"
							className="w-full border p-1 rounded-md"
							value={fields?.created_at || new Date()}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="overflow-hidden">
					{fields?.images?.length < 4 && (
						<p className="font-semibold">Add photos</p>
					)}
					<div className="flex gap-3 mb-2 flex-wrap ">
						{fields?.images?.length < 4 && (
							<div
								className=" relative mt-4"
								style={{
									maxWidth: "140px",
									flex: "1 1 140px",
									aspectRatio: "1/1",
								}}
							>
								<input
									type="file"
									name="photo"
									onChange={handleChange}
									accept="image/png,image/jpeg,image/jpg,image/gif"
									width={120}
									height={120}
									className="absolute left-0 top-0 w-full h-full z-50 opacity-0 cursor-pointer"
								/>
								<div className=" border-2 border-[#DFE2ED] border-dashed w-full h-full flex items-center justify-center flex-col p-1 rounded-[10px] ">
									<div>
										<svg
											width="50"
											height="50"
											viewBox="0 0 50 50"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M25.5312 0C11.7244 0 0.53125 11.1932 0.53125 25C0.53125 38.8068 11.7244 50 25.5312 50C39.3381 50 50.5312 38.8068 50.5312 25C50.5312 11.1932 39.3381 0 25.5312 0ZM27.804 34.0909C27.804 34.6937 27.5645 35.2718 27.1383 35.698C26.7121 36.1242 26.134 36.3636 25.5312 36.3636C24.9285 36.3636 24.3504 36.1242 23.9242 35.698C23.498 35.2718 23.2585 34.6937 23.2585 34.0909V27.2727H16.4403C15.8376 27.2727 15.2595 27.0333 14.8333 26.6071C14.4071 26.1808 14.1676 25.6028 14.1676 25C14.1676 24.3972 14.4071 23.8192 14.8333 23.3929C15.2595 22.9667 15.8376 22.7273 16.4403 22.7273H23.2585V15.9091C23.2585 15.3063 23.498 14.7282 23.9242 14.302C24.3504 13.8758 24.9285 13.6364 25.5312 13.6364C26.134 13.6364 26.7121 13.8758 27.1383 14.302C27.5645 14.7282 27.804 15.3063 27.804 15.9091V22.7273H34.6222C35.2249 22.7273 35.803 22.9667 36.2292 23.3929C36.6554 23.8192 36.8949 24.3972 36.8949 25C36.8949 25.6028 36.6554 26.1808 36.2292 26.6071C35.803 27.0333 35.2249 27.2727 34.6222 27.2727H27.804V34.0909Z"
												fill="#000"
											/>
										</svg>
									</div>
								</div>
							</div>
						)}

						{fields?.images?.map((image, index) => {
							return (
								<div
									key={index}
									className=" relative mt-4"
									style={{
										maxWidth: "140px",

										flex: "1 1 140px",
										aspectRatio: "1/1",
									}}
								>
									<img
										className="w-full h-full rounded-[10px] object-contain bg-gray-200 "
										src={image?.preview || image}
										width={120}
										height={120}
									/>
									<div
										className="absolute -right-1 -top-3 cursor-pointer"
										onClick={(e) =>
											handleImageRemove(index)
										}
									>
										<svg
											width="22"
											height="23"
											viewBox="0 0 22 23"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="w-6 h-6 "
										>
											<circle
												cx="11"
												cy="11.5"
												r="7.5"
												fill="white"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M3.22175 3.72175C-1.07392 8.01743 -1.07392 14.9824 3.22175 19.2781C7.51743 23.5738 14.4824 23.5738 18.7781 19.2781C23.0738 14.9824 23.0738 8.01743 18.7781 3.72175C14.4824 -0.573918 7.51743 -0.573918 3.22175 3.72175ZM14.5355 13.6212C14.723 13.8088 14.8284 14.0631 14.8284 14.3284C14.8284 14.5936 14.723 14.8479 14.5355 15.0355C14.3479 15.223 14.0936 15.3284 13.8284 15.3284C13.5631 15.3284 13.3088 15.223 13.1212 15.0355L10.9999 12.9141L8.87861 15.0355C8.69107 15.223 8.43672 15.3284 8.1715 15.3284C7.90629 15.3284 7.65193 15.223 7.4644 15.0355C7.27686 14.8479 7.1715 14.5936 7.1715 14.3284C7.1715 14.0631 7.27686 13.8088 7.4644 13.6212L9.58572 11.4999L7.4644 9.37861C7.27686 9.19107 7.1715 8.93672 7.1715 8.6715C7.1715 8.40629 7.27686 8.15193 7.4644 7.9644C7.65193 7.77686 7.90629 7.6715 8.1715 7.6715C8.43672 7.6715 8.69107 7.77686 8.87861 7.9644L10.9999 10.0857L13.1212 7.9644C13.3088 7.77686 13.5631 7.6715 13.8284 7.6715C14.0936 7.6715 14.3479 7.77686 14.5355 7.9644C14.723 8.15193 14.8284 8.40629 14.8284 8.6715C14.8284 8.93672 14.723 9.19107 14.5355 9.37861L12.4141 11.4999L14.5355 13.6212Z"
												fill="#EB5757"
											/>
										</svg>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<button
					onClick={handleSubmit}
					disabled={loading}
					className={`${
						loading && "cursor-not-allowed"
					} mt-3 inline-block   bg-[#60579E]  text-white px-4 py-2 ml-auto w-24 text-center rounded  transition-colors duration-150`}
				>
					Submit
				</button>
				<button
					onClick={handlePopupClose}
					disabled={loading}
					className={`${
						loading && "cursor-not-allowed"
					} mt-3 inline-block   border border-[#60579E]  text-[#60579E]  px-4 py-2 ml-4 w-24 text-center rounded  transition-colors duration-150`}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}
